import { Button } from "primereact/components/button/Button";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Dialog } from "primereact/components/dialog/Dialog";
import { InputText } from "primereact/components/inputtext/InputText";
import { OverlayPanel } from "primereact/components/overlaypanel/OverlayPanel";
import React, { useEffect, useRef, useState } from "react";
import { addUser, deleteUser, getUsers, updateUser } from "../../Services/Api";

type UsersProps = {
    displayModal: boolean,
    setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>,
    user: UserRow | undefined | null
}

const Users = ({ displayModal, setDisplayModal, user }: UsersProps) => {

    const [users, setUsers] = useState<UserRow[]>([])
    const [addUserEmail, setAddUserEmail] = useState<string>('')
    const [addUserIsAdmin, setAddUserIsAdmin] = useState<boolean>(false)
    const [currentUserToDelete, setCurrentUserToDelete] = useState<UserRow | null>(null)

    const confirmDeleteOverlayPanelRef = useRef<OverlayPanel>(null);

    const fetchUsers = (): void => {
        getUsers()
            .then((data: any) => {
                let users = data.data as UserRow[];

                // console.log('users', users);

                setUsers(users);
                // setDisplayModal(true);
            })
            .catch((err: Error) => console.log(err))
    }

    useEffect(fetchUsers, []);

    const toggleIsAdmin = async (rowData: UserRow) => {
        // console.log('toggleIsAdmin', rowData);
        rowData.isAdmin = !rowData.isAdmin;
        await updateUser(rowData.email, rowData.isAdmin);
        setUsers(users => [...users]);
    }

    const askDeleteUser = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowData: UserRow) => {
        // console.log('confirmDeleteUser', rowData);
        confirmDeleteOverlayPanelRef.current?.show(e, e.currentTarget);
        setCurrentUserToDelete(rowData);
    }

    const onConfirmDeleteUser = async (user: UserRow) => {
        await deleteUser(user.email);
        setUsers(users => users.filter(u => u.email !== user.email));
    }

    const onAddUser = async () => {
        addUser(addUserEmail, addUserIsAdmin).then((data: any) => {
            let newUser = data.data as UserRow;

            console.log('newUser', newUser);
            setUsers(users => [...users, newUser]);
            setAddUserEmail('');
            setAddUserIsAdmin(false);
        })
            .catch((err: Error) => console.log(err));
    }

    const renderFooterUsersModal = () => {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() => setDisplayModal(false)} className="p-button-text" />
            </div>
        );
    }

    const isAdminBodyTemplate = (rowData: UserRow) => {
        // console.log('isAdminBodyTemplate', rowData);
        return <span>
            <Button icon="pi pi-user" disabled={user && user.email !== rowData.email ? undefined : true} className={'p-button-rounded p-button-info ' + (rowData.isAdmin ? '' : 'p-button-outlined')} onClick={() => toggleIsAdmin(rowData)} />
        </span>;
    }

    const actionBodyTemplate = (rowData: UserRow) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" disabled={user && user.email !== rowData.email ? undefined : true} className="p-button-rounded p-button-danger" onClick={(e) => askDeleteUser(e, rowData)} />
            </React.Fragment>
        );
    }

    return (
        <div>
            <Dialog header="Users" visible={displayModal} style={{ width: '800px' }} footer={renderFooterUsersModal()} onHide={() => setDisplayModal(false)}>

                <DataTable className="p-mt-3" value={users}>
                    <Column field="email" header='Email' style={{ width: '35%' }}></Column>
                    <Column field="displayName" header='DisplayName' style={{ width: '35%' }}></Column>
                    <Column body={isAdminBodyTemplate} header="Admin" style={{ width: '20%' }}></Column>
                    <Column body={actionBodyTemplate} style={{ width: '10%' }}></Column>
                </DataTable>
                <div className="p-formgroup-inline p-pt-3 p-d-inline-flex p-ai-center">
                    <div className="p-field">
                        <label htmlFor="email" className="p-sr-only">Email</label>
                        <InputText id="email" type="text" placeholder="Email" value={addUserEmail} onChange={(e) => setAddUserEmail(e.currentTarget.value)} style={{ width: '550px' }} />
                    </div>
                    <div className="p-field-checkbox">
                        <Checkbox inputId="isAdmin" value="Admin" checked={addUserIsAdmin} onChange={(e) => setAddUserIsAdmin(e.checked)} />
                        <label htmlFor="isAdmin">Is Admin</label>
                    </div>
                    <Button type="button" label="Add" className="p-mb-3" onClick={() => onAddUser()} />
                </div>
            </Dialog>


            <OverlayPanel ref={confirmDeleteOverlayPanelRef} >
                <div className="confirmation-content">
                    <span className="p-text-left">{'Are you sure you want to delete ' + currentUserToDelete?.email + "?"}</span>
                </div>
                <div className="p-mt-5">
                    <Button label="No" icon="pi pi-times" onClick={() => confirmDeleteOverlayPanelRef.current?.hide()} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={(e) => { confirmDeleteOverlayPanelRef.current?.hide(); onConfirmDeleteUser(currentUserToDelete as UserRow) }} autoFocus />
                </div>
            </OverlayPanel>
        </div>
    )
}

export default Users;