import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { getWizardSteps, updateWizardStep } from "../../Services/Api";
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from 'primereact/inputtextarea';

type WizardStepsControlProps = {
}

const WizardStepsControl = () => {
    const [wizardSteps, setWizardSteps] = useState<WizardStep[] | undefined>()
    const [editingWizardStep, setEditingWizardStep] = useState<WizardStep | undefined>()
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false)


    useEffect(() => {
        getWizardSteps(true)
            .then((data: any) => {
                let newWizardSteps = data.data as WizardStep[];
                // console.log('wizardSteps', newWizardSteps);
                setWizardSteps(newWizardSteps);
            })
            .catch((err: Error) => console.log(err));
    }, [])


    const editWizardStep = (rowData: WizardStep) => {
        rowData.listValuesStr = rowData.listValues.join('\n');
        setEditingWizardStep(rowData);
        setShowEditDialog(true);
    }

    const listValuesBodyTemplate = (rowData: WizardStep) => {
        let strListValues = rowData.listValues.join(', ');
        let strListValuesShort = strListValues;

        if (strListValues.length > 50) {
            strListValuesShort = strListValues.substring(0, 50) + '...';
        }

        return (
            <React.Fragment>
                <div title={strListValues} style={{ whiteSpace: 'nowrap' }}>
                    {strListValuesShort}
                </div>
            </React.Fragment>
        );
    }

    const multipleBodyTemplate = (rowData: WizardStep) => {
        return (
            <React.Fragment>
                <Checkbox checked={rowData.multiple === 1} disabled={true}></Checkbox>
            </React.Fragment>
        );
    }

    const visibleBodyTemplate = (rowData: WizardStep) => {
        return (
            <React.Fragment>
                <Checkbox checked={rowData.visible === 1} disabled={true}></Checkbox>
            </React.Fragment>
        );
    }

    const actionBodyTemplate = (rowData: WizardStep) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" onClick={() => editWizardStep(rowData)} />
            </React.Fragment>
        );
    }

    const editDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={(e) => setShowEditDialog(false)} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={(e) => saveWizardStep()} />
        </React.Fragment>
    );

    const saveWizardStep = async () => {
        setShowEditDialog(false);
        // console.log('editingWizardStep', editingWizardStep);
        // console.log('wizardSteps', wizardSteps);

        if (wizardSteps && editingWizardStep) {
            editingWizardStep.listValues = editingWizardStep.listValuesStr.split('\n');
            await updateWizardStep(editingWizardStep);
            let index = wizardSteps?.findIndex(x => x.wizardStepId === editingWizardStep?.wizardStepId);
            const newWizardSteps = [...wizardSteps];
            newWizardSteps[index] = editingWizardStep;
            setWizardSteps(newWizardSteps);
            // console.log('newWizardSteps', newWizardSteps);
        }
    }

    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        let data = { ...editingWizardStep } as any;
        data[`${name}`] = val;

        setEditingWizardStep(data)
    }

    const onCheckboxChange = (e: any, name: string) => {
        // console.log('e.target', e.target);
        const val = (e.target && e.target.checked) || false;
        let data = { ...editingWizardStep } as any;
        data[`${name}`] = val ? 1 : 0;

        setEditingWizardStep(data)
    }

    return (
        <div>
            <DataTable value={wizardSteps} editMode="row" dataKey="wizardStepId"
                paginator rows={10}
                paginatorTemplate="PageLinks">
                <Column field="controlType" header="Control Type" style={{ width: '80px' }}></Column>
                <Column field="label" header="Label" ></Column>
                <Column body={listValuesBodyTemplate} header="List Values" ></Column>
                <Column field="placeholder" header="Placeholder" style={{ width: '150px' }}></Column>
                <Column field="propertyName" header="Property Name" style={{ width: '150px' }}></Column>
                <Column body={multipleBodyTemplate} header='Multiple' style={{ width: '100px' }}></Column>
                <Column body={visibleBodyTemplate} header='Visible' style={{ width: '100px' }}></Column>
                <Column body={actionBodyTemplate} style={{ width: '100px' }} ></Column>
            </DataTable>

            <Dialog visible={showEditDialog} style={{ width: '800px' }} header="Edit Wizard Step" modal className="p-fluid" footer={editDialogFooter} onHide={() => setShowEditDialog(false)}>
                <div className="p-field">
                    <label htmlFor="controlType">Control Type</label>
                    <InputText id="controlType" value={editingWizardStep?.controlType} disabled={true} />
                </div>
                <div className="p-field">
                    <label htmlFor="label">Label</label>
                    <InputText id="label" value={editingWizardStep?.label} onChange={e => onInputChange(e, 'label')} autoFocus required />
                </div>
                <div className="p-field">
                    <label htmlFor="listValues">List Values</label>
                    <InputTextarea id="listValues" disabled={editingWizardStep?.controlType !== 'list' ? true : undefined} value={editingWizardStep?.listValuesStr} rows={8} cols={30} onChange={e => onInputChange(e, 'listValuesStr')} autoFocus required />
                </div>
                <div className="p-field">
                    <label htmlFor="placeholder">Placeholder</label>
                    <InputText id="placeholder" value={editingWizardStep?.placeholder} onChange={e => onInputChange(e, 'placeholder')} required />
                </div>
                <div className="p-field">
                    <label htmlFor="propertyName">Property Name</label>
                    <InputText id="propertyName" value={editingWizardStep?.propertyName} disabled={true} />
                </div>
                <div className="p-field-checkbox">
                    <Checkbox inputId='multiple' checked={editingWizardStep?.multiple === 1} disabled={true}></Checkbox>
                    <label htmlFor="multiple">Multiple</label>
                </div>
                <div className="p-field-checkbox">
                    <Checkbox inputId='visible' checked={editingWizardStep?.visible === 1} onChange={e => onCheckboxChange(e, 'visible')} ></Checkbox>
                    <label htmlFor="visible">Visible</label>
                </div>
            </Dialog>
        </div>
    )
}

export default WizardStepsControl;