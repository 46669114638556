import React, { useEffect, useRef, useState } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import WizardStepsControl from "../../Components/WizardStepsControl/WizardStepsControl";
import SessionGroupsControl from "../../Components/SessionGroupsControl/SessionGroupsControl";
import firebase from 'firebase';
import { ProgressBar } from 'primereact/progressbar';
import Login from "./Login";
import { getUser } from "../../Services/Api";
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import { Menu } from 'primereact/components/menu/Menu';
import { Button } from "primereact/button";
import Users from "../../Components/Users/Users";


const Admin = () => {
    const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null)
    const [user, setUser] = useState<UserRow | undefined | null>(undefined)

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const [displayUsersModal, setDisplayUsersModal] = useState<boolean>(false);
    const menuRef = useRef<Menu>(null);

    useEffect(() => {
        setUser(undefined);
        console.log('setUser(undefined);');
        // console.log('process.env.NODE_ENV', process.env.NODE_ENV);

        // console.log('Register => onAuthStateChanged');
        let unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            async (user) => {

                console.log('onAuthStateChanged', user);

                setFirebaseUser(user);
                setUser(undefined);

                if (user) {
                    getUser(user.email!)
                        .then((data: any) => {
                            let userRow = data.data as UserRow;

                            if (userRow) {
                                setUser(userRow);
                                console.log('setUser(userRow);');
                                // console.log('onAuthStateChanged => getUser', userRow);
                            } else {
                                setUser(null);
                                console.log('setUser(null);');
                            }
                        })
                        .catch((err: Error) => console.log(err))
                } else {
                    setUser(null);
                    console.log('setUser(null);');
                }

                // console.log('user', user);
            }
        );

        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const items: MenuItem[] = [
        {
            label: 'Users',
            icon: 'pi pi-user',
            disabled: !user?.isAdmin,
            command: () => {
                setDisplayUsersModal(true);
            }
        },
        {
            label: 'Sign out',
            icon: 'pi pi-sign-out',
            command: (e) => {
                signOut();
            }
        }
    ];

    const signOut = () => {
        firebase.auth().signOut();
        setUser(undefined);
        console.log('setUser(undefined);');
    }

    const start = () => (
        <div className="p-d-inline-flex p-ai-center">
            <Button icon="pi pi-bars" className="p-button-rounded p-button-text p-mr-2" onClick={(event) => menuRef.current?.toggle(event)} aria-controls="popup_menu" aria-haspopup />
            <img alt="logo" src="logo192.png" height="40" className="p-mr-2"></img>
            <p className="p-ml-2">{user ? user.displayName : ''}</p>
            <h2 className="p-pl-6">Admin</h2>
        </div>
    )

    return (
        user === undefined ?
            <div className="p-d-flex p-jc-center">
                <div className="p-d-flex p-flex-column p-pt-3">
                    <h2 className="p-m-2 p-p-2 p-as-center">Loading...</h2>
                    <ProgressBar mode="indeterminate" style={{ width: '500px' }} />
                </div>
            </div>
            :
            user === null ?
                <Login firebaseUser={firebaseUser} signOut={signOut} />
                :
                <div className="p-d-flex p-jc-center" style={{ height: '100%' }}>
                    <div className="p-d-flex p-flex-column card" style={{ height: '100%' }}>
                        <div>
                            <Menubar start={start} />
                            <Menu model={items} popup ref={menuRef} id="popup_menu" />
                        </div>
                        <div className="p-d-flex p-jc-center p-pt-6" style={{ height: '100%' }}>
                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                <TabPanel header="Session Groups">
                                    <SessionGroupsControl />
                                </TabPanel>
                                <TabPanel header="Wizard Steps">
                                    <WizardStepsControl />
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>

                    <Users displayModal={displayUsersModal} setDisplayModal={setDisplayUsersModal} user={user} />
                </div>
    )
}

export default Admin;