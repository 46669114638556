import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Dispatch, SetStateAction } from "react";

type ConfirmModalProps = {
    displayConfirm: boolean;
    setDisplayConfirm: Dispatch<SetStateAction<boolean>>;
    confirm: any;
}

const ConfirmModal = ({ displayConfirm, setDisplayConfirm: setDisplayConfirmation, confirm }: ConfirmModalProps) => {
    const renderConfirmationFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => confirm()} autoFocus />
            </div>
        );
    }

    return (
        <Dialog header="Confirmation" visible={displayConfirm} modal style={{ width: '350px' }} footer={renderConfirmationFooter()} onHide={() => setDisplayConfirmation(false)}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                <span>Are you sure you want to proceed?</span>
            </div>
        </Dialog>
    )
}

export default ConfirmModal;