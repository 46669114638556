import { v4 as uuidv4 } from 'uuid';

export const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * Math.floor(max - min)) + Math.floor(min);
}

export const createEmptyPerson = (sessionId: string, isSurveyPerson: number): SessionPerson => {
    return {
        sessionPersonId: 0,
        sessionId: sessionId,
        sessionGroupId: 0,
        isSurveyPerson: isSurveyPerson,
        name: '',
        gender: '',
        ethnicity: '',
        age: '',
        sexualOrientation: '',
        religion: '',
        levelOfEducation: '',
        disability: '',
        military: '',
        maritalStatus: '',
        createdOn: new Date(),
        trustedPeople: [],
    } as SessionPerson;
}

export const getWizardDataAsSingleArray = (data: WizardData) => {
    let people = [];

    data.person.isSurveyPerson = 1;

    people.push(data.person);

    for (let i = 0; i < data.trustedPeople.length; i++) {
        if (data.trustedPeople[i].name !== '')
            people.push(data.trustedPeople[i]);
    }

    // console.log('people', people);

    return people;
}

export const getSampleData = (wizardSteps: WizardStep[] | undefined): WizardData => {
    let sessionId = uuidv4();

    let data = {
        person: createEmptyPerson(sessionId, 1),
        trustedPeople: [
        ]
    } as WizardData

    let names = ['Gabriele', 'Sandra', 'Derrick', 'Jim', 'Jake', 'Milly'];

    data.person.name = names[getRandomInt(0, names.length)];

    let nTrustedPeople = getRandomInt(2, 5);

    for (let i = 0; i < nTrustedPeople; i++) {
        let trustedPerson = createEmptyPerson(sessionId, 0);
        trustedPerson.name = names[getRandomInt(0, names.length)];
        data.trustedPeople.push(trustedPerson);
    }

    let people = getWizardDataAsSingleArray(data);

    if (wizardSteps) {
        for (let t = 0; t < people.length; t++) {
            let p = people[t] as any;

            for (let i = 0; i < wizardSteps.length; i++) {
                let ws = wizardSteps[i];

                if (!ws.multiple) {
                    if (ws.propertyName !== 'name') {
                        p[ws.propertyName] = ws.listValues[getRandomInt(0, ws.listValues.length)];
                    }
                }
            }
        }
    }

    return data;
}
