
import React from "react";
import './LoginControl.css';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from "primereact/button";

type LoginControlProps = {
    setUsername: (username: string) => void;
    setPasssword: (password: string) => void;
    login: () => void;
    loginError: boolean;
    setLoginError: (loginError: boolean) => void;
}

const LoginControl = ({ setUsername, setPasssword, login, loginError, setLoginError }: LoginControlProps) => {

    const onUsernameChange = (newValue: string) => {
        setUsername(newValue);
        setLoginError(false);
    }

    const onPasswordChange = (newValue: string) => {
        setPasssword(newValue);
        setLoginError(false);
    }

    return (
        <div className="p-d-flex p-jc-center p-ai-center" style={{ height: '100%' }}>
            <div className="p-d-flex p-flex-column">
                <div className="p-as-center">
                    <img src="/logo192.png" alt="Logo" />
                </div>
                <div className="p-formgroup-inline">
                    <div className="p-field">
                        <label htmlFor="username" className="p-sr-only">Username</label>
                        <InputText id="username" type="text" placeholder="Username" onChange={(e) => onUsernameChange(e.currentTarget.value)} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="password" className="p-sr-only">Password</label>
                        <Password id="password" feedback={false} type="text" placeholder="Password" onChange={(e) => onPasswordChange(e.currentTarget.value)} />
                    </div>
                    <Button type="button" label="Login" onClick={() => login()} />
                </div>
                {
                    loginError ?
                        <span className="p-invalid">Invalid username of password.</span>
                        :
                        <></>
                }
            </div>
        </div>
    )

}

export default LoginControl;