import React, { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import './Wizard.css';
import { getWizardSteps, loginSessionGroup, setCurrentAccessToken, submitSurvey } from "../../Services/Api";
import useLocalStorage from "../../Helpers/Hooks";
import WizardControl from "../../Components/WizardControl/WizardControl";
import { createEmptyPerson, getWizardDataAsSingleArray } from "../../Helpers/Utility";
import SessionPeopleControl from "../../Components/SessionPeopleControl/SessionPeopleControl";
import ConfirmModal from '../../Components/Modals/ConfirmModal/ConfirmModal';
import { v4 as uuidv4 } from 'uuid';
import LoginControl from "../../Components/Login/LoginControl";

const Wizard = () => {

    const [wizardSteps, setWizardSteps] = useState<WizardStep[] | undefined>()
    const [wizardData, setWizardData] = useLocalStorage<WizardData | undefined>('wizardData', undefined)
    const [currentStepIndex, setCurrentStepIndex] = useLocalStorage<number>('currentStepIndex', 0);
    const [dataAsSingleArray, setDataAsSingleArray] = useState<SessionPerson[] | undefined>()
    const [currentStepDataSelected, setCurrentStepDataSelected] = useState<boolean>(false);
    const [displayConfirm, setDisplayConfirm] = useState<boolean>(false);
    const [surveyCompleted, setSurveyCompleted] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<boolean>(false);
    const [accessToken, setAccessToken] = useLocalStorage<string | undefined>('accessToken', undefined);

    const refreshWizardSteps = () => {
        getWizardSteps(false)
            .then((data: any) => {
                let newWizardSteps = data.data as WizardStep[];

                newWizardSteps = newWizardSteps.filter(s => s.visible === 1);

                // console.log('wizardSteps', newWizardSteps);
                setWizardSteps(newWizardSteps);
            })
            .catch((err: Error) => console.log(err));
    }

    const changeCurrentAccessToken = (accessToken: string) => {
        setAccessToken(accessToken);
        setCurrentAccessToken(accessToken);
        setAuthenticated(true);
        refreshWizardSteps();
    }

    useEffect(() => {
        // console.log('accessToken', accessToken);
        if (accessToken !== undefined && accessToken !== '') {
            setCurrentAccessToken(accessToken);
            setAuthenticated(true);
            refreshWizardSteps();
        }
    }, [accessToken])


    useEffect(() => {
        if (wizardData === undefined) {
            let sessionId = uuidv4();
            let data = {
                person: createEmptyPerson(sessionId, 1),
                trustedPeople: [
                    createEmptyPerson(sessionId, 0),
                    createEmptyPerson(sessionId, 0),
                    createEmptyPerson(sessionId, 0),
                    createEmptyPerson(sessionId, 0),
                    createEmptyPerson(sessionId, 0)
                ]
            } as WizardData;

            // console.log('data', data);

            setWizardData(data);
        }

    }, [wizardSteps, setWizardSteps, wizardData, setWizardData])

    // useEffect(() => {
    //     if (wizardData === undefined) {
    //         let data = getSampleData(wizardSteps);
    //         setCurrentStepIndex(20);

    //         // console.log('data', data);

    //         setWizardData(data);
    //     }

    // }, [wizardSteps, setWizardSteps, wizardData, setWizardData])

    useEffect(() => {
        if (wizardSteps && currentStepIndex === wizardSteps.length && wizardData !== undefined) {
            setDataAsSingleArray(getWizardDataAsSingleArray(wizardData));
        }
    }, [currentStepIndex, wizardData, wizardSteps])


    const isBackButtonDisabled = (): boolean | undefined => {
        return currentStepIndex === 0 ? true : undefined;
    }

    const onBackClick = () => {
        if (currentStepIndex > 0) {
            setCurrentStepIndex(currentStepIndex - 1);
            // console.log('onBackClick', wizardData);
            setWizardData(wizardData);
        }
    }

    const isNextButtonDisabled = (): boolean | undefined => {
        return wizardSteps && (currentStepIndex === wizardSteps.length || !currentStepDataSelected) ? true : undefined;
    }

    const onNextClick = () => {
        if (wizardSteps && currentStepIndex < wizardSteps.length) {
            setCurrentStepIndex(currentStepIndex + 1);
            // console.log('onNextClick', wizardData);
            setWizardData(wizardData);
        }
    }

    const onConfirmClick = () => {
        setDisplayConfirm(true);
    }

    const submitData = async () => {
        if (wizardData) {
            setDisplayConfirm(false);
            // console.log('submitData', wizardData);

            await submitSurvey(wizardData)
                .then(() => {
                    setSurveyCompleted(true);
                    setWizardData(undefined);
                    setCurrentStepIndex(0);
                    setAccessToken('');
                })
                .catch((err: Error) => console.log(err));
        }
    }

    const checkCurrentStepDataSelected = () => {
        let ok = false;

        if (wizardSteps) {
            let ws = wizardSteps[currentStepIndex];

            if (ws.multiple) {
                if (ws.propertyName !== 'name') {
                    if (wizardData && wizardData.trustedPeople) {
                        ok = true;

                        for (let i = 0; i < wizardData?.trustedPeople.length; i++) {
                            let obj = wizardData.trustedPeople[i] as any;
                            let nameValue = obj['name'];

                            if (nameValue !== '') {
                                let value = obj[ws.propertyName];

                                if (value === '') {
                                    ok = false;
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    ok = true;
                }
            } else {
                if (wizardData && wizardData.person) {
                    let obj = wizardData?.person as any;
                    let value = obj[ws.propertyName];
                    ok = value !== '';
                    // console.log('isCurrentValueSelected', data);
                }
            }
        }

        setCurrentStepDataSelected(ok);
    }

    const login = () => {
        loginSessionGroup(username, password).then((data: any) => {
            let response = data.data as LoginSessionGroupResponse;
            // console.log('response', response);
            changeCurrentAccessToken(response.accessToken);
        }).catch((err: Error) => {
            setAuthenticated(false);
            setLoginError(true);
            console.log(err)
        });
    }

    let CurrentControl = (<LoginControl setUsername={setUsername} setPasssword={setPassword} login={login} loginError={loginError} setLoginError={setLoginError} />);

    if (authenticated) {
        if (surveyCompleted) {
            CurrentControl = (
                <div className="p-d-flex p-jc-center" style={{ height: '100%' }}>
                    <div className="p-d-flex p-flex-column" style={{ width: '75%', height: '100%' }}>
                        <div>
                            <h1 className="p-pb-0 label" style={{ textAlign: "center" }}>Survey Completed</h1>
                            <hr />
                        </div>
                        <div className="p-d-flex p-jc-center p-pt-6" style={{ height: '100%' }}>
                            <h2>Thank you for participating in the survey! You can now close the browser.</h2>
                        </div>
                    </div>
                </div>
            )
        } else if (wizardSteps) {
            if (currentStepIndex === wizardSteps.length) {
                CurrentControl = (
                    <div className="p-d-flex p-jc-center" style={{ height: '100%' }}>
                        <div className="p-d-flex p-flex-column" style={{ width: '75%', height: '100%' }}>
                            <div>
                                <h1 className="p-pb-0 label" style={{ textAlign: "center" }}>Summary</h1>
                                <hr />
                            </div>
                            <div className="p-d-flex p-jc-center p-pt-6" style={{ height: '100%' }}>
                                <SessionPeopleControl people={dataAsSingleArray} showExpander={false} />
                            </div>
                            <div className="p-d-flex p-jc-center p-ai-center p-p-3 p-mb-6 p-mt-auto">
                                <Button label="Back" icon="pi pi-arrow-left" className="p-button-lg" style={{ marginRight: '50px', height: '40px' }} disabled={isBackButtonDisabled()} onClick={() => onBackClick()} />
                                <img src="/trusted_five.jpg" alt="Logo" style={{ height: '70px' }} />
                                <Button label="Confirm" icon="pi pi-arrow-right" iconPos="right" className="p-button-lg" style={{ marginLeft: '50px', height: '40px' }} onClick={() => onConfirmClick()} />
                            </div>
                            <ConfirmModal displayConfirm={displayConfirm} setDisplayConfirm={setDisplayConfirm} confirm={submitData} />
                        </div>
                    </div>
                )
            } else {
                CurrentControl = (
                    <div className="p-d-flex p-jc-center" style={{ height: '100%' }}>
                        <div className="p-d-flex p-flex-column" style={{ width: '75%', height: '100%' }}>
                            <div>
                                <h1 className="p-pb-0 label" style={{ textAlign: "center" }}>{wizardSteps && wizardSteps[currentStepIndex].label}</h1>
                                <hr />
                            </div>
                            <div className="p-pt-0 p-mt-auto" >
                                <div className="p-d-flex p-jc-center">
                                    {
                                        wizardSteps && wizardSteps[currentStepIndex].multiple ?
                                            <div className="p-d-flex p-flex-column">
                                                {
                                                    wizardData?.trustedPeople.map((p: SessionPerson, index: number) => (
                                                        wizardSteps[currentStepIndex].propertyName === 'name' ||
                                                            (p.name !== '') ?
                                                            <div className='p-m-2' key={index} >
                                                                <WizardControl wizardStep={wizardSteps[currentStepIndex]} sessionPerson={p} checkCurrentStepDataSelected={checkCurrentStepDataSelected} ></WizardControl>
                                                            </div>
                                                            :
                                                            <div key={index}></div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <WizardControl wizardStep={wizardSteps && wizardSteps[currentStepIndex]} sessionPerson={wizardData?.person} checkCurrentStepDataSelected={checkCurrentStepDataSelected}></WizardControl>
                                    }
                                </div>
                            </div>
                            <div className="p-d-flex p-jc-center p-ai-center p-p-3 p-mb-6 p-mt-auto">
                                <Button label="Back" icon="pi pi-arrow-left" className="p-button-lg" style={{ marginRight: '50px', height: '40px' }} disabled={isBackButtonDisabled()} onClick={() => onBackClick()} />
                                <img src="/trusted_five.jpg" alt="Logo" style={{ height: '70px' }} />
                                <Button label="Next" icon="pi pi-arrow-right" iconPos="right" className="p-button-lg" style={{ marginLeft: '50px', height: '40px' }} disabled={isNextButtonDisabled()} onClick={() => onNextClick()} />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    return CurrentControl;
}

export default Wizard;