import { Dropdown } from "primereact/components/dropdown/Dropdown";
import { InputText } from "primereact/components/inputtext/InputText";
import React, { useEffect, useState } from "react";
import './WizardControl.css';

type WizardControlProps = {
    sessionPerson: SessionPerson | undefined;
    wizardStep: WizardStep | undefined;
    checkCurrentStepDataSelected: any;
}

const WizardControl = ({ wizardStep, sessionPerson, checkCurrentStepDataSelected }: WizardControlProps) => {

    const [currentWizardValue, setCurrentWizardValue] = useState<string>('');


    const loadCurrentWizardValue = () => {
        if (sessionPerson && wizardStep) {
            let obj = sessionPerson as any;
            let propertyName = wizardStep.propertyName;
            let value = obj[propertyName];
            setCurrentWizardValue(value);
            checkCurrentStepDataSelected();
            // console.log(`loadCurrentWizardValue(${propertyName})`, data);
        }
    }

    const saveCurrentWizardValue = (value: string) => {
        if (sessionPerson && wizardStep) {
            let obj = sessionPerson as any;
            let propertyName = wizardStep.propertyName;

            obj[propertyName] = value;

            // console.log('saveCurrentWizardValue', data);

            setCurrentWizardValue(value);
            checkCurrentStepDataSelected();
        }
    }

    useEffect(() => {
        loadCurrentWizardValue();
    })


    return (
        <div className="p-d-flex p-jc-end p-ai-center">
            {
                wizardStep && wizardStep.multiple && wizardStep.propertyName !== 'name' ?
                    <label className="field-label p-p-3" >{sessionPerson?.name}</label>
                    :
                    <div></div>
            }
            <div>
                {
                    wizardStep && wizardStep.controlType === 'text' ?
                        <InputText type="text" value={currentWizardValue} onChange={(e) => saveCurrentWizardValue(e.currentTarget.value)} className="p-inputtext-lg p-d-block text" placeholder={wizardStep.placeholder} style={{ width: '500px' }} />
                        :
                        <Dropdown className="dropdown" value={currentWizardValue} onChange={(e) => saveCurrentWizardValue(e.value)} options={wizardStep && wizardStep.listValues} placeholder={wizardStep && wizardStep.placeholder} style={{ width: '500px' }} />
                }
            </div>
        </div>

    )

}

export default WizardControl;