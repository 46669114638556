import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { getRandomInt } from "../../Helpers/Utility";
import { createSessionGroup, deleteSessionGroup, getSessionGroups, updateSessionGroup } from "../../Services/Api";
import './SessionGroupsControl.css';
import copy from 'copy-to-clipboard';
import SessionPeopleControl from "../SessionPeopleControl/SessionPeopleControl";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/components/inputtext/InputText";
import { Password } from 'primereact/password';
import ConfirmModal from "../Modals/ConfirmModal/ConfirmModal";


const SessionGroupsControl = () => {
    const [sessionGroups, setSessionGroups] = useState<SessionGroup[] | undefined>()
    const [sessionGroupPeople, setSessionGroupPeople] = useState<SessionPerson[] | undefined>()
    const [editingSessionGroup, setEditingSessionGroup] = useState<SessionGroup | undefined>()
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false)
    const [displayConfirm, setDisplayConfirm] = useState<boolean>(false);

    useEffect(() => {
        getSessionGroups()
            .then((data: any) => {
                let sessionGroups = data.data as SessionGroup[];
                console.log('sessionGroups', sessionGroups);

                sessionGroups.forEach(sg => sg.fakePasswordHidden = getFakeHiddenPassword())
                setSessionGroups(sessionGroups);
            })
            .catch((err: Error) => console.log(err));
    }, [])

    const rowExpansionTemplate = (data: SessionGroup) => {
        console.log('SessionGroupsControl => rowExpansionTemplate', data);
        return (
            <SessionPeopleControl people={data.people} showExpander={true} />
        );
    }

    const copyPasswordToClipboardHandler = (rowData: SessionGroup) => {
        copy(rowData.password);
    }

    const getFakeHiddenPassword = () => {
        let nChars = getRandomInt(8, 15);
        return "*".repeat(nChars);
    }

    const passwordTemplate = (rowData: SessionGroup) => {
        return (
            <React.Fragment>
                <div className="p-d-flex p-flex-row p-ai-center">
                    <div style={{ width: '100px' }}>{rowData.fakePasswordHidden}</div>
                    <Button icon="pi pi-copy" className="p-button-rounded p-button-text p-mr-2" onClick={() => copyPasswordToClipboardHandler(rowData)} />
                </div>
            </React.Fragment>
        );
    }

    const editDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => setShowEditDialog(false)} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => saveSessionGroupHandler()} />
        </React.Fragment>
    );

    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        let data = { ...editingSessionGroup } as any;
        data[`${name}`] = val;

        setEditingSessionGroup(data)
    }

    const openNew = () => {
        setEditingSessionGroup(undefined);
        setShowEditDialog(true);
    }

    const saveSessionGroupHandler = async () => {
        setShowEditDialog(false);
        // console.log('editingSessionGroup', editingSessionGroup);
        // console.log('sessionGroups', sessionGroups);

        if (sessionGroups && editingSessionGroup) {

            let newSessionGroups = [...sessionGroups];

            if (editingSessionGroup.sessionGroupId) {
                await updateSessionGroup(editingSessionGroup).then((data: any) => {
                    // let sessionGroup = data.data as SessionGroup;
                    // console.log('sessionGroup', sessionGroup);

                    let index = newSessionGroups?.findIndex(x => x.sessionGroupId === editingSessionGroup?.sessionGroupId);
                    newSessionGroups[index] = editingSessionGroup;

                    setSessionGroups(newSessionGroups);
                    // console.log('newSessionGroups', newSessionGroups);
                })
                    .catch((err: Error) => console.log(err));
            } else {
                createSessionGroup(editingSessionGroup).then((data: any) => {
                    let sessionGroup = data.data as SessionGroup;
                    // console.log('sessionGroup', sessionGroup);
                    sessionGroup.fakePasswordHidden = getFakeHiddenPassword();
                    console.log('sessionGroup', sessionGroup);
                    newSessionGroups.push(sessionGroup);

                    setSessionGroups(newSessionGroups);
                    // console.log('newSessionGroups', newSessionGroups);
                })
                    .catch((err: Error) => console.log(err));
            }
        }
    }

    const deleteSessionGroupHandler = async () => {
        console.log('deleteSessionGroupHandler', editingSessionGroup, sessionGroups);
        if (editingSessionGroup && sessionGroups) {
            let newSessionGroups = sessionGroups.filter(sg => sg.sessionGroupId !== editingSessionGroup.sessionGroupId);
            setSessionGroups(newSessionGroups);
            setDisplayConfirm(false);
            await deleteSessionGroup(editingSessionGroup);
        }
    }

    const actionBodyTemplate = (rowData: SessionGroup) => {
        return (
            <React.Fragment>
                <div className='p-d-inline-flex p-flex-row '>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2" onClick={() => editSessionGroup(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2" onClick={() => askDeleteSessionGroup(rowData)} />
                </div>
            </React.Fragment>
        );
    }

    const editSessionGroup = (rowData: SessionGroup) => {
        setEditingSessionGroup(rowData);
        setShowEditDialog(true);
    }

    const askDeleteSessionGroup = (rowData: SessionGroup) => {
        setEditingSessionGroup(rowData);
        setDisplayConfirm(true);
    }

    // console.log('sessionGroupPeople', sessionGroupPeople);

    return (
        <div className="p-d-flex p-flex-column">
            <div className="p-d-flex p-p-3 card">
                <Button label="New" icon="pi pi-plus" className="p-button p-mr-2" onClick={openNew} />
            </div>
            <DataTable value={sessionGroups} expandedRows={sessionGroupPeople} onRowToggle={(e) => { setSessionGroupPeople(e.data) }}
                rowExpansionTemplate={rowExpansionTemplate} dataKey="sessionGroupId"
                paginator rows={10}
                paginatorTemplate="PageLinks" >
                <Column expander style={{ width: '3em' }} />
                <Column field="username" header="Username" />
                <Column body={passwordTemplate} header="Password" />
                <Column body={actionBodyTemplate} style={{ width: '100px' }} ></Column>
            </DataTable>

            <ConfirmModal displayConfirm={displayConfirm} setDisplayConfirm={setDisplayConfirm} confirm={deleteSessionGroupHandler} />

            <Dialog visible={showEditDialog} style={{ width: '800px' }} header="Edit Session Group" modal className="p-fluid" footer={editDialogFooter} onHide={() => setShowEditDialog(false)}>
                <div className="p-field">
                    <label htmlFor="username">Username</label>
                    <InputText id="username" value={editingSessionGroup?.username || ''} onChange={e => onInputChange(e, 'username')} autoFocus required />
                </div>
                <div className="p-field">
                    <label htmlFor="password">Password</label>
                    <Password id="password" value={editingSessionGroup?.password || ''} onChange={e => onInputChange(e, 'password')} required />
                </div>
            </Dialog>
        </div >
    )

}

export default SessionGroupsControl;