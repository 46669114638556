import React from 'react';
import './App.css';
import 'primeflex/primeflex.css';
import Wizard from './Pages/Wizard/Wizard';
import {
  Route,
  BrowserRouter
} from "react-router-dom";
import Admin from './Pages/Admin/Admin';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Route exact path="/" component={Wizard} />
        <Route exact path="/admin" component={Admin} />
      </BrowserRouter>
    </div>
  );
}

export default App;
