import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import firebase from 'firebase';

const baseUrl: string = (window.location.href.indexOf("https://") !== -1 ? "https://" : "http://") + window.location.hostname + (process.env.NODE_ENV !== 'production' ? ':5000' : '');

console.log('window.location.href', window.location.href);

let currentAccessToken: string | undefined = undefined;

let idToken: string | undefined = undefined;
let dateAndTimeIdToken: Date = new Date();

const getRequestConfig = async (): Promise<AxiosRequestConfig> => {
    let diff = new Date().getTime() - dateAndTimeIdToken.getTime();

    let minutes = diff / 1000 / 60;

    if (minutes > 55) {
        idToken = undefined;
    }

    if (idToken === undefined) {
        idToken = await firebase.auth().currentUser?.getIdToken(true);
        dateAndTimeIdToken = new Date();
    }

    let config = {
        headers: {
            "Authorization": "Bearer " + idToken
        },
    } as AxiosRequestConfig

    // console.log('config', config);

    return config;
}

export const setCurrentAccessToken = (newAccessToken: string) => {
    currentAccessToken = newAccessToken;
}

const getRequestConfigSessionGroup = () => {
    return {

        headers: {
            "Authorization": "Bearer " + currentAccessToken,
        },
    } as AxiosRequestConfig;
}

export const loginSessionGroup = async (username: string, password: string): Promise<AxiosResponse<LoginSessionGroupResponse>> => {
    try {
        const results: AxiosResponse<LoginSessionGroupResponse> = await axios.post(baseUrl + "/api/login", {
            username: username,
            password: password,
        } as LoginSessionGroupRequest);
        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const submitSurvey = async (data: WizardData): Promise<AxiosResponse<SubmitSurveyResponse>> => {
    try {
        let surveyData = { ...data };

        surveyData.trustedPeople = surveyData.trustedPeople.filter(tp => tp.name !== '');

        const results: AxiosResponse<SubmitSurveyResponse> = await axios.post(baseUrl + "/api/surveys", {
            data: surveyData
        } as SubmitSurveyRequest, getRequestConfigSessionGroup());

        return results
    } catch (error) {
        throw new Error(error)
    }
}


export const getWizardSteps = async (fromAdmin: boolean): Promise<AxiosResponse<GetWizardStepsResponse>> => {
    try {
        const results: AxiosResponse<GetWizardStepsResponse> = await axios.get(baseUrl + (fromAdmin ? "/api/admin/wizardsteps" : "/api/wizardsteps"),
            fromAdmin ? await getRequestConfig() : getRequestConfigSessionGroup());
        return results;
    } catch (error) {
        throw new Error(error)
    }
}

export const updateWizardStep = async (wizardStep: WizardStep): Promise<AxiosResponse<UpdateWizardStepResponse>> => {
    try {
        const results: AxiosResponse<UpdateWizardStepResponse> = await axios.put(baseUrl + "/api/admin/wizardsteps/" + wizardStep.wizardStepId, {
            controlType: wizardStep.controlType,
            label: wizardStep.label,
            listValues: wizardStep.listValues,
            placeholder: wizardStep.placeholder,
            visible: wizardStep.visible
        } as UpdateWizardStepRequest, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const getSessionGroups = async (): Promise<AxiosResponse<GetSessionGroupsResponse>> => {
    try {
        const results: AxiosResponse<GetSessionGroupsResponse> = await axios.get(baseUrl + "/api/admin/sessiongroups/", await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const createSessionGroup = async (sessionGroup: SessionGroup): Promise<AxiosResponse<CreateOrUpdateSessionGroupResponse>> => {
    try {
        const results: AxiosResponse<CreateOrUpdateSessionGroupResponse> = await axios.post(baseUrl + "/api/admin/sessiongroups/", {
            username: sessionGroup.username,
            password: sessionGroup.password
        } as CreateOrUpdateSessionGroupRequest, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const updateSessionGroup = async (sessionGroup: SessionGroup): Promise<AxiosResponse<CreateOrUpdateSessionGroupResponse>> => {
    try {
        const results: AxiosResponse<CreateOrUpdateSessionGroupResponse> = await axios.put(baseUrl + "/api/admin/sessiongroups/" + sessionGroup.sessionGroupId, {
            username: sessionGroup.username,
            password: sessionGroup.password
        } as CreateOrUpdateSessionGroupRequest, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const deleteSessionGroup = async (sessionGroup: SessionGroup): Promise<AxiosResponse<DeleteSessionGroupResponse>> => {
    try {
        const results: AxiosResponse<DeleteSessionGroupResponse> = await axios.delete(baseUrl + "/api/admin/sessiongroups/" + sessionGroup.sessionGroupId, await getRequestConfig());

        return results
    } catch (error) {
        console.log(error);
        throw new Error(error)
    }
}


export const getUsers = async (): Promise<AxiosResponse<GetUsersResponse>> => {
    try {
        const results: AxiosResponse<GetUsersResponse> = await axios.get(baseUrl + "/api/admin/users", await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const getUser = async (email: string): Promise<AxiosResponse<GetUserResponse>> => {
    try {
        const results: AxiosResponse<GetUserResponse> = await axios.get(baseUrl + "/api/admin/users/" + email, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const addUser = async (email: string, isAdmin: boolean): Promise<AxiosResponse<AddUserResponse>> => {
    try {
        const results: AxiosResponse<AddUserResponse> = await axios.post(baseUrl + "/api/admin/users", {
            email: email,
            isAdmin: isAdmin
        } as AddUserRequest, await getRequestConfig());

        return results;
    } catch (error) {
        throw new Error(error)
    }
}

export const updateUser = async (email: string, isAdmin: boolean): Promise<AxiosResponse<UpdateUserResponse>> => {
    try {
        const results: AxiosResponse<UpdateUserResponse> = await axios.put(baseUrl + "/api/admin/users/" + email, {
            isAdmin: isAdmin
        } as UpdateUserRequest, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}

export const deleteUser = async (email: string): Promise<AxiosResponse<DeleteUserResponse>> => {
    try {
        const results: AxiosResponse<DeleteUserResponse> = await axios.delete(baseUrl + "/api/admin/users/" + email, await getRequestConfig());

        return results
    } catch (error) {
        throw new Error(error)
    }
}