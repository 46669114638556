import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import './SessionPeopleControl.css';

type SessionPeopleControlProps = {
    people: SessionPerson[] | undefined;
    showExpander: boolean;
}

const SessionPeopleControl = ({ people, showExpander }: SessionPeopleControlProps) => {
    const [trustedPeople, setTrustedPeople] = useState<SessionPerson[] | undefined>()

    useEffect(() => {
        console.log('SessionPeopleControl', people);
    }, [people])

    const rowClass = (data: SessionPerson) => {
        return {
            'row-is-survey-person': !showExpander && data.isSurveyPerson === 1
        }
    }

    const rowExpansionTemplate = (data: SessionPerson) => {
        console.log('SessionPeopleControl => rowExpansionTemplate', data);
        return (
            <DataTable value={data.trustedPeople} resizableColumns columnResizeMode="fit" className="p-datatable-gridlines">
                <Column field="name" header="Name"></Column>
                <Column field="gender" header="Gender"></Column>
                <Column field="ethnicity" header="Ethnicity"></Column>
                <Column field="age" header="Age"></Column>
                <Column field="sexualOrientation" header="Sexual Orientation"></Column>
                <Column field="religion" header="Religion"></Column>
                <Column field="levelOfEducation" header="Level of education"></Column>
                <Column field="disability" header="Disability"></Column>
                <Column field="military" header="Military"></Column>
                <Column field="maritalStatus" header="MaritalStatus"></Column>
            </DataTable>
        );
    }

    let ExpanderColumn = null;

    if (showExpander)
        ExpanderColumn = (<Column expander style={{ width: '4em' }} />);

    return (
        <DataTable value={people} expandedRows={trustedPeople} onRowToggle={(e) => { setTrustedPeople(e.data) }}
            rowExpansionTemplate={rowExpansionTemplate} dataKey="sessionId"
            resizableColumns columnResizeMode="fit" className="p-datatable-gridlines" rowClassName={rowClass}>
            {ExpanderColumn}
            <Column field="name" header="Name"></Column>
            <Column field="gender" header="Gender"></Column>
            <Column field="ethnicity" header="Ethnicity"></Column>
            <Column field="age" header="Age"></Column>
            <Column field="sexualOrientation" header="Sexual Orientation"></Column>
            <Column field="religion" header="Religion"></Column>
            <Column field="levelOfEducation" header="Level of education"></Column>
            <Column field="disability" header="Disability"></Column>
            <Column field="military" header="Military"></Column>
            <Column field="maritalStatus" header="MaritalStatus"></Column>
        </DataTable>
    )

}

export default SessionPeopleControl;